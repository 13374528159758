

//Style
import './styles.css'

const App: React.FC = () => {
	


	
	


		


	return (
		<>
			<div className='center'>
				<img className="logo" src={require('./assets/icons/logo.jpeg')}></img>
				<div className='divider' ></div>
				<p className='text'>WEBSITE IN PROGRESS</p>
			</div>
		</>
	)
}


export default App